import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Text } from '@reservamos/elements';
import PropTypes from 'prop-types';
import mastercard from 'images/payment/mastercard.svg';
import visa from 'images/payment/visa.svg';
import amex from 'images/payment/americanexpress.svg';
import 'styles/components/purchase/CardFormPaymentOptions';

const cardImages = {
  visa,
  mastercard,
  amex,
};

/**
 * Renders logos of accepted credit/debit cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string[]} props.availableCards - An array of available card types (e.g., 'visa', 'mastercard', 'amex').
 * @returns {React.ReactElement|null} The rendered component or null if logos should not be shown.
 */
const CardsLogos = ({ availableCards = [] }) => {
  const { t } = useTranslation('payment');
  const { SHOW_CREDIT_DEBIT_LOGOS } = useSelector((state) => state.whitelabelConfig.features);

  if (!SHOW_CREDIT_DEBIT_LOGOS) {
    return null;
  }

  const cardsToShow = availableCards.length > 0 ? availableCards : ['visa', 'mastercard', 'amex'];

  return (
    <div className="card-container">
      <div className="cards-logos">
        {cardsToShow.map(
          (card) =>
            cardImages[card] && <img key={card} src={cardImages[card]} alt={`${card} logo`} />,
        )}
      </div>
    </div>
  );
};

CardsLogos.propTypes = {
  availableCards: PropTypes.array,
};

export default CardsLogos;
